<template>
  <vx-card style="padding-left: 10px">
    <vs-prompt
      title="Export as CSV"
      class="export-options"
      @cancle="clearFields"
      @accept="exportToExcel"
      accept-text="Export"
      @close="clearFields"
      :active.sync="activePrompt"
    >
      <vs-input
        v-model="fileName"
        placeholder="Enter File Name.."
        class="w-full"
      />
    </vs-prompt>
    <div id="data-list-list-view" class="data-list-container">
      <vs-table
        ref="table"
        :sst="true"
        :total="totalDocs"
        :data="users"
        @search="handleSearch"
        @change-page="handleChangePage"
        @sort="handleSort"
        :max-items="dataTableParams.limit"
        search
      >
        <div
          slot="header"
          class="flex flex-wrap-reverse items-center flex-grow justify-between"
        >
          <!-- <vs-row vs-type="flex-start" vs-justify="flex-start" vs-w="6"> -->
          <div
            slot="header"
            class="flex items-center flex-grow"
            vs-type="flex-start"
            vs-justify="flex-start"
          >
            <vx-tooltip text="Add new User" position="top" delay=".3s">
              <vs-button
                type="gradient"
                size="small"
                icon-pack="feather"
                icon="icon-user-plus"
                color="success"
                @click="addDetailHandler()"
              ></vs-button>
            </vx-tooltip>
            <vx-tooltip text="Export Users" position="top" delay=".3s">
              <vs-button
                type="gradient"
                size="small"
                class="ml-1"
                icon-pack="feather"
                icon="icon-download"
                color="danger"
                @click="getUsersForCSV"
                >Export</vs-button
              >
            </vx-tooltip>
            <!-- </vs-row> -->
          </div>
          <div
            class="flex flex-wrap-reverse items-center data-list-btn-container"
          ></div>
          <v-select
            v-model="dataTableParams.limit"
            class="mr-4"
            :options="limitOptions"
            :clearable="false"
          />
        </div>

        <template slot="thead">
          <vs-th sort-key="email">Email</vs-th>
          <vs-th sort-key="firstName">Name</vs-th>
          <!-- <vs-th sort-key="lastName">Last Name</vs-th> -->
          <vs-th sort-key="status">Status</vs-th>
          <vs-th sort-key="hasActiveSubscription">Subscription</vs-th>
          <vs-th sort-key="createdAt">Created At</vs-th>
          <vs-th sort-key="emailVerifiedAt">Verified</vs-th>
          <!-- <vs-th sort-key="updatedAt">Updated At</vs-th> -->
          <vs-th sort-key="action" size="25%">Actions</vs-th>
        </template>

<template slot-scope="{ data }">
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <vs-td :data="data[indextr].email">{{ data[indextr].email }}</vs-td>
            <vs-td :data="data[indextr].firstName"
              >{{ data[indextr].firstName }}
              {{ data[indextr].lastName ? data[indextr].lastName : "" }}</vs-td
            >
            <!-- <vs-td :data="data[indextr].lastName">{{ data[indextr].lastName }}</vs-td> -->
            <vs-td :data="data[indextr].status">{{
              data[indextr].status
            }}</vs-td>
            <vs-td>
               <vx-tooltip :text='data[indextr].subscriptionStatus' position="top" delay=".3s">
              <span class="flex items-center px-2 py-1 rounded">
                <div class="h-3 w-3 rounded-full mr-2" 
                :class='data[indextr].hasActiveSubscription?"bg-success":"bg-danger"'
                ></div>
                {{data[indextr].subscriptionStatus}}
                {{data[indextr].isInTrialPeriod?" (On Trial)":''}}
                </span>
             </vx-tooltip>
            </vs-td>
            <vs-td :data="data[indextr].createdAt">{{
              moment(data[indextr].createdAt).format("DD/MM/YYYY")
            }}</vs-td>
            <vs-td :data="data[indextr].emailVerifiedAt">{{
              data[indextr].emailVerifiedAt ? "Yes" : "No"
            }}</vs-td>
            <!-- <vs-td
              :data="data[indextr].updatedAt"
            >{{ moment(data[indextr].updatedAt).format('DD/MM/YYYY') }}</vs-td>-->
            <vs-td :data="data[indextr]._id">
              <vs-row vs-type="flex" vs-justify="space-between">
             
                <vx-tooltip text="View" position="top" delay=".3s">
                  <vs-button
                    type="gradient"
                    size="small"
                    icon-pack="feather"
                    @click="viewDetailHandler(data[indextr]._id)"
                    icon="icon-user"
                    color="primary"
                  ></vs-button>
                </vx-tooltip>
                  <vx-tooltip text="Edit" position="top" delay=".3s">
                  <vs-button
                    type="gradient"
                    size="small"
                    icon-pack="feather"
                    icon="icon-edit"
                    @click="editDetailHandler(data[indextr]._id)"
                    color="warning"
                  ></vs-button>
                </vx-tooltip>
                <vx-tooltip text="Delete" position="top" delay=".3s">
                  <vs-button
                    type="gradient"
                    size="small"
                    icon-pack="feather"
                    @click="deleteDetailHandler(data[indextr], indextr)"
                    icon="icon-trash"
                    color="danger"
                  ></vs-button>
                </vx-tooltip>
              </vs-row>
            </vs-td>
          </vs-tr>
        </template>
</vs-table>
<span class="mr-2">{{ currentPage * dataTableParams.limit - (dataTableParams.limit - 1) }}
        -
        {{
          totalDocs - currentPage * dataTableParams.limit > 0
            ? currentPage * dataTableParams.limit
            : totalDocs
        }}
        of {{ totalDocs }}</span
      >
      <vs-pagination
        :total="totalPage"
        v-model="dataTableParams.page"
      ></vs-pagination>
    </div>
  </vx-card>
</template>

<script>
import vSelect from "vue-select";
import { mapActions } from "vuex";
import moment from "moment";

export default {
  components: {
    "v-select": vSelect,
  },
  data() {
    return {
      isMounted: false,
      totalDocs: 0,
      currentPage: 1,
      dataTableParams: {
        search: "",
        sort: "createdAt",
        dir: "desc",
        page: 1,
        limit: 10,
      },
      users: [],
      limitOptions: [5, 10, 25, 50, 100],
      selectedUser: {},

      fileName: "",
      cellAutoWidth: true,
      selectedFormat: "csv",
      headerTitle: [
        "Id",
        "Email",
        "contactNumber",
        "DisplayAddress",
        "Status",
        "Subscription Status",
        "EmailVerifiedAt",
        "CreatedAt",
      ],
      headerVal: [
        "_id",
        "email",
        "contactNumber",
        "displayAddress",
        "status",
        "hasActiveSubscription",
        "emailVerifiedAt",
        "createdAt",
      ],
      selectedUsersForCsv: [],
      activePrompt: false,
    };
  },
  methods: {
    ...mapActions("user", ["fetchUsers", "removeUser"]),
    ...mapActions(["initToFirstPage"]),
    moment(date) {
      return moment(date);
    },
    getUsersForCSV() {
      let self = this;
      let dataTableParams = {
        search: this.dataTableParams.search,
        sort: "createdAt",
        dir: "desc",
        page: 1,
        limit: this.totalDocs,
      };
      this.fetchUsers(dataTableParams).then((res) => {
        self.selectedUsersForCsv = res.data.data.docs;
        this.activePrompt = true;
      });
    },
    getUsersList() {
      let self = this;
      this.fetchUsers(self.dataTableParams).then((res) => {
        self.users = res.data.data.docs;
        self.totalDocs = res.data.data.pagination.total;
        self.page = res.data.data.pagination.page;
        self.currentPage = self.page;
      });
    },
    handleSearch(searching) {
      this.dataTableParams.search = searching;
      this.dataTableParams.page = 1;
      this.$refs.table.currentx = 1;
      this.getUsersList();
    },
    handleChangePage(page) {
      this.dataTableParams.page = page;
      this.getUsersList();
    },
    handleSort(key, active) {
      this.dataTableParams.sort = key;
      this.dataTableParams.dir = active;
      this.dataTableParams.page = 1;
      this.$refs.table.currentx = 1;
      this.getUsersList();
    },
    editDetailHandler(id) {
      this.$router.push({ name: "EditUserDetail", params: { id: id } });
    },
    viewDetailHandler(id) {
      this.$router.push({ name: "UserDetail", params: { id: id } });
    },
    addDetailHandler() {
      this.$router.push({ name: "AddUserDetail" });
    },
    deleteDetailHandler(user, i) {
      this.selectedUser = user;
      this.selectedIndex = i;

      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: `Confirm Delete`,
        text: `You are about to delete "${user.email}"`,
        accept: this.confirmDeleteRecord,
        acceptText: "Delete",
      });
    },
    confirmDeleteRecord() {
      this.removeUser(this.selectedUser._id).then((res) => {
        this.$vs.notify({
          title: "User Deleted",
          text: res.data.message,
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "success",
        });
        this.users.splice(this.selectedIndex, 1);
        this.totalDocs -= 1;
      });
    },
    exportToExcel() {
      import("@/vendor/Export2Excel").then((excel) => {
        const list = this.selectedUsersForCsv;
        const data = this.formatJson(this.headerVal, list);
        excel.export_json_to_excel({
          header: this.headerTitle,
          data,
          filename: this.fileName,
          autoWidth: this.cellAutoWidth,
          bookType: this.selectedFormat,
        });
        this.clearFields();
      });
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map((v) =>
        filterVal.map((j) => {
          // Add col name which needs to be translated
          // if (j === 'timestamp') {
          //   return parseTime(v[j])
          // } else {
          //   return v[j]
          // }

          return v[j];
        })
      );
    },
    clearFields() {
      this.fileName = "";
      this.cellAutoWidth = true;
      this.selectedFormat = "csv";
    },
  },
  computed: {
    totalPage: function () {
      return this.totalDocs / this.dataTableParams.limit >
        parseInt(this.totalDocs / this.dataTableParams.limit)
        ? parseInt(this.totalDocs / this.dataTableParams.limit) + 1
        : parseInt(this.totalDocs / this.dataTableParams.limit);
    },
  },
  watch: {
    "dataTableParams.page": function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.dataTableParams.page = newVal;
        this.getUsersList();
      }
    },
    "dataTableParams.limit": function (newlimit, oldLimit) {
      if (newlimit !== oldLimit) {
        this.dataTableParams.page = 1;
        this.dataTableParams.limit = newlimit;
        this.getUsersList();
      }
    },
  },
  created() {
    this.initToFirstPage({ pageType: "all"});
    this.getUsersList();
  },
};
</script>
